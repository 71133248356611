<template>
 <div>
  <svg height="183" viewBox="0 0 183 183" width="183" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradient" x1="0.565" x2="0.435" y1="-5.898" y2="-4.635">
     <stop offset="0" stop-color="#2c8c9d"/>
     <stop offset="0.04" stop-color="#298e9a"/>
     <stop offset="0.39" stop-color="#129c83"/>
     <stop offset="0.72" stop-color="#05a475"/>
     <stop offset="1" stop-color="#00a770"/>
    </linearGradient>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradient-2" x1="-0.015" x2="1.015" y1="-8.501" y2="-7.601">
     <stop offset="0" stop-color="#2839ba"/>
     <stop offset="1" stop-color="#148cb3"/>
    </linearGradient>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradient-3" x2="1" y1="-10.835" y2="-10.835">
     <stop offset="0" stop-color="#f6a500"/>
     <stop offset="1" stop-color="#f29100"/>
    </linearGradient>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradient-4" x2="1" y1="-10.835" y2="-10.835">
     <stop offset="0" stop-color="#f29100"/>
     <stop offset="1" stop-color="#d12f5f"/>
    </linearGradient>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradient-5" x2="1" y1="-10.835" y2="-10.835">
     <stop offset="0" stop-color="#b4197c"/>
     <stop offset="1" stop-color="#472ead"/>
    </linearGradient>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradient-6" x2="1" y1="-10.835" y2="-10.835">
     <stop offset="0" stop-color="#2839ba"/>
     <stop offset="1" stop-color="#3072b7"/>
    </linearGradient>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradient-7" x1="-0.039" x2="1.039" y1="-8.321" y2="-7.781">
     <stop offset="0" stop-color="#b4197c"/>
     <stop offset="1" stop-color="#373aac"/>
    </linearGradient>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradient-8" x1="-0.035" x2="1.035" y1="-7.717" y2="-8.385">
     <stop offset="0" stop-color="#ffcb00"/>
     <stop offset="1" stop-color="#d12f5f"/>
    </linearGradient>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradient-9" x1="0.085" x2="0.915" y1="-4.609" y2="-5.923">
     <stop offset="0" stop-color="#ffcf00"/>
     <stop offset="1" stop-color="#ed743b"/>
    </linearGradient>
   </defs>
   <g id="icon_deezer" transform="translate(-390 -2975)">
    <g data-name="Group 424" id="Group_424" transform="translate(260 -33)">
     <rect :fill="background" data-name="Rectangle 212" height="183" id="Rectangle_212" transform="translate(130 3008)" width="183"/>
     <g id="Colored_Full_Black" transform="translate(93.563 3903.391)">
      <path d="M299.156-776.251c-2.434,0-4.437-1.467-4.473-4.043h11.527a9.547,9.547,0,0,0,.226-2.075c0-4.654-3.185-7.624-7.872-7.624-5.011,0-8.446,3.9-8.446,8.731,0,5.368,3.722,8.948,8.982,8.948,3.973,0,6.728-2,7.911-5.3l-3.917-1.53A3.691,3.691,0,0,1,299.156-776.251Zm-.608-10.056c2.034,0,3.436,1.11,3.436,2.828l-.036.142h-7.264A3.815,3.815,0,0,1,298.548-786.307Z" data-name="Path 476" fill="#fff" id="Path_476" transform="translate(-161.013 -18.668)"/>
      <path d="M363.539-785.014v-3.759H348.759v3.937h9.018l-9.305,9.09v3.935h15.317v-4.116H354.2Z" data-name="Path 477" fill="#fff" id="Path_477" transform="translate(-202.28 -19.531)"/>
      <path d="M472.249-784.159a6.01,6.01,0,0,1-.056.852h4.342a8.529,8.529,0,0,0,.151-1.675,4.658,4.658,0,0,0-4.866-5.011,4.225,4.225,0,0,0-4.079,2.541v-2.181h-4.579v16.951h4.579v-10.905c0-1.718.931-2.683,2.362-2.683A2.019,2.019,0,0,1,472.249-784.159Z" data-name="Path 478" fill="#fff" id="Path_478" transform="translate(-283.387 -18.668)"/>
      <path d="M180.149-805.761a6.073,6.073,0,0,0-5.368-2.9c-4.654,0-8.053,3.58-8.053,8.912,0,5.259,3.366,8.767,8.159,8.767a6.2,6.2,0,0,0,5.44-2.791v2.432h4.4v-25.05h-4.579Zm-4.333,10.89c-2.54,0-4.52-1.86-4.52-4.866,0-3.042,1.969-4.972,4.52-4.972,2.468,0,4.509,1.932,4.509,4.972A4.527,4.527,0,0,1,175.816-794.872Z" data-name="Path 479" fill="#fff" id="Path_479" transform="translate(-73.753)"/>
      <path d="M410.579-776.251c-2.434,0-4.437-1.467-4.473-4.043h11.527a9.511,9.511,0,0,0,.226-2.075c0-4.654-3.185-7.624-7.872-7.624-5.011,0-8.446,3.9-8.446,8.731,0,5.368,3.723,8.948,8.982,8.948,3.973,0,6.728-2,7.911-5.3l-3.919-1.53A3.686,3.686,0,0,1,410.579-776.251Zm-.608-10.056c2.034,0,3.436,1.11,3.436,2.828l-.036.142H406.1A3.822,3.822,0,0,1,409.971-786.307Z" data-name="Path 480" fill="#fff" id="Path_480" transform="translate(-239.81 -18.668)"/>
      <path d="M241.367-776.251c-2.434,0-4.437-1.467-4.473-4.043h11.527a9.381,9.381,0,0,0,.215-2.075c0-4.654-3.185-7.624-7.872-7.624-5.011,0-8.446,3.9-8.446,8.731,0,5.368,3.722,8.948,8.982,8.948,3.973,0,6.729-2,7.911-5.3l-3.906-1.53A3.691,3.691,0,0,1,241.367-776.251Zm-.608-10.056c2.034,0,3.435,1.11,3.435,2.828l-.036.142h-7.264A3.815,3.815,0,0,1,240.759-786.307Z" data-name="Path 481" fill="#fff" id="Path_481" transform="translate(-120.137 -18.668)"/>
      <g data-name="Group 408" id="Group_408" transform="translate(62.438 -808.316)">
       <path d="M137.528-788.812h-5.612v3.275h5.612Z" data-name="Path 482" fill="#29ab70" id="Path_482" transform="translate(-111.572 788.812)"/>
       <path d="M137.528-773.235h-5.612v3.275h5.612Z" data-name="Path 483" fill="url(#linear-gradient)" id="Path_483" transform="translate(-111.572 777.796)"/>
       <path d="M137.528-757.665h-5.612v3.275h5.612Z" data-name="Path 484" fill="url(#linear-gradient-2)" id="Path_484" transform="translate(-111.572 766.785)"/>
       <path d="M68.05-742.089H62.438v3.275H68.05Z" data-name="Path 485" fill="url(#linear-gradient-3)" id="Path_485" transform="translate(-62.438 755.77)"/>
       <path d="M91.207-742.089H85.6v3.275h5.612Z" data-name="Path 486" fill="url(#linear-gradient-4)" id="Path_486" transform="translate(-78.814 755.77)"/>
       <path d="M114.371-742.089h-5.612v3.275h5.612Z" data-name="Path 487" fill="url(#linear-gradient-5)" id="Path_487" transform="translate(-95.196 755.77)"/>
       <path d="M137.528-742.089h-5.612v3.275h5.612Z" data-name="Path 488" fill="url(#linear-gradient-6)" id="Path_488" transform="translate(-111.572 755.77)"/>
       <path d="M114.371-757.665h-5.612v3.275h5.612Z" data-name="Path 489" fill="url(#linear-gradient-7)" id="Path_489" transform="translate(-95.196 766.785)"/>
       <path d="M91.207-757.665H85.6v3.275h5.612Z" data-name="Path 490" fill="url(#linear-gradient-8)" id="Path_490" transform="translate(-78.814 766.785)"/>
       <path d="M91.207-773.235H85.6v3.275h5.612Z" data-name="Path 491" fill="url(#linear-gradient-9)" id="Path_491" transform="translate(-78.814 777.796)"/>
      </g>
      <path d="M299.156-776.251c-2.434,0-4.437-1.467-4.473-4.043h11.527a9.547,9.547,0,0,0,.226-2.075c0-4.654-3.185-7.624-7.872-7.624-5.011,0-8.446,3.9-8.446,8.731,0,5.368,3.722,8.948,8.982,8.948,3.973,0,6.728-2,7.911-5.3l-3.917-1.53A3.691,3.691,0,0,1,299.156-776.251Zm-.608-10.056c2.034,0,3.436,1.11,3.436,2.828l-.036.142h-7.264A3.815,3.815,0,0,1,298.548-786.307Z" data-name="Path 492" id="Path_492" transform="translate(-161.013 -18.668)"/>
      <path d="M363.539-785.014v-3.759H348.759v3.937h9.018l-9.305,9.09v3.935h15.317v-4.116H354.2Z" data-name="Path 493" id="Path_493" transform="translate(-202.28 -19.531)"/>
      <path d="M472.249-784.159a6.01,6.01,0,0,1-.056.852h4.342a8.529,8.529,0,0,0,.151-1.675,4.658,4.658,0,0,0-4.866-5.011,4.225,4.225,0,0,0-4.079,2.541v-2.181h-4.579v16.951h4.579v-10.905c0-1.718.931-2.683,2.362-2.683A2.019,2.019,0,0,1,472.249-784.159Z" data-name="Path 494" id="Path_494" transform="translate(-283.387 -18.668)"/>
      <path d="M180.149-805.761a6.073,6.073,0,0,0-5.368-2.9c-4.654,0-8.053,3.58-8.053,8.912,0,5.259,3.366,8.767,8.159,8.767a6.2,6.2,0,0,0,5.44-2.791v2.432h4.4v-25.05h-4.579Zm-4.333,10.89c-2.54,0-4.52-1.86-4.52-4.866,0-3.042,1.969-4.972,4.52-4.972,2.468,0,4.509,1.932,4.509,4.972A4.527,4.527,0,0,1,175.816-794.872Z" data-name="Path 495" id="Path_495" transform="translate(-73.753)"/>
      <path d="M410.579-776.251c-2.434,0-4.437-1.467-4.473-4.043h11.527a9.511,9.511,0,0,0,.226-2.075c0-4.654-3.185-7.624-7.872-7.624-5.011,0-8.446,3.9-8.446,8.731,0,5.368,3.723,8.948,8.982,8.948,3.973,0,6.728-2,7.911-5.3l-3.919-1.53A3.686,3.686,0,0,1,410.579-776.251Zm-.608-10.056c2.034,0,3.436,1.11,3.436,2.828l-.036.142H406.1A3.822,3.822,0,0,1,409.971-786.307Z" data-name="Path 496" id="Path_496" transform="translate(-239.81 -18.668)"/>
      <path d="M241.367-776.251c-2.434,0-4.437-1.467-4.473-4.043h11.527a9.381,9.381,0,0,0,.215-2.075c0-4.654-3.185-7.624-7.872-7.624-5.011,0-8.446,3.9-8.446,8.731,0,5.368,3.722,8.948,8.982,8.948,3.973,0,6.729-2,7.911-5.3l-3.906-1.53A3.691,3.691,0,0,1,241.367-776.251Zm-.608-10.056c2.034,0,3.435,1.11,3.435,2.828l-.036.142h-7.264A3.815,3.815,0,0,1,240.759-786.307Z" data-name="Path 497" id="Path_497" transform="translate(-120.137 -18.668)"/>
     </g>
    </g>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>